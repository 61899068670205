import React from "react";

const ETH: React.FC<SVGProps> = (props) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="25" cy="25" r="25" fill="#EDF0F4" />
      <path
        d="M24.9312 10L24.7323 10.6755V30.2762L24.9312 30.4747L34.0295 25.0966L24.9312 10Z"
        fill="#343434"
      />
      <path
        d="M24.9314 10L15.8329 25.0966L24.9314 30.4747V20.961V10Z"
        fill="#8C8C8C"
      />
      <path
        d="M24.9317 32.1972L24.8197 32.3339V39.316L24.9317 39.6432L34.0356 26.822L24.9317 32.1972Z"
        fill="#3C3C3B"
      />
      <path
        d="M24.9313 39.6432V32.1972L15.8328 26.822L24.9313 39.6432Z"
        fill="#8C8C8C"
      />
      <path
        d="M24.9316 30.4746L34.0298 25.0966L24.9316 20.961V30.4746Z"
        fill="#141414"
      />
      <path
        d="M15.8328 25.0966L24.9313 30.4746V20.961L15.8328 25.0966Z"
        fill="#393939"
      />
    </svg>
  );
};

export default ETH;
