import React from "react";

const SHIB: React.FC<SVGProps> = (props) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 50C11.25 50 0 38.7502 0 24.8755C0.125 11.1258 11.25 -0.123966 25.125 0.00103168C38.875 0.126029 50 11.2508 50 25.2505C49.875 38.8752 38.75 50 25 50"
        fill="#E42D04"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4868 43.3333C27.3014 43.3333 31.9561 42.5153 36.0586 39.2432C40.7759 35.4805 41.2204 33.3821 41.5071 32.0291C41.532 31.9114 41.5557 31.7993 41.5811 31.6921C41.6289 31.4894 41.6568 31.2141 41.6665 30.8869C40.6756 30.7264 39.2867 30.6345 37.6659 30.8269C35.0151 31.1415 32.9323 32.9506 32.2223 33.8159C31.8278 31.8494 29.9028 27.9166 25.3585 27.9166C20.8143 27.9166 18.3633 31.8494 17.7059 33.8159C17.2851 33.0817 15.7177 31.4562 12.8145 30.8269C11.0193 30.4379 9.45347 30.6301 8.33313 30.9575C8.42158 32.4197 8.90221 33.9793 10.1814 35.625C12.3825 38.4566 15.7979 40.2051 17.3216 40.9852C17.4909 41.0719 17.6369 41.1466 17.7552 41.2096C18.9386 41.8388 22.1732 43.3333 25.4868 43.3333Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.6629 30.8294C40.6725 30.6694 39.2845 30.5777 37.6647 30.7696C35.0154 31.0833 32.9338 32.8872 32.2242 33.75C31.8299 31.7892 29.906 27.8676 25.3644 27.8676C20.8228 27.8676 18.3733 31.7892 17.7162 33.75C17.2957 33.0179 15.7292 31.397 12.8276 30.7696C11.0336 30.3817 9.4686 30.5734 8.34891 30.8999C8.26829 29.5702 8.51361 28.3214 8.77686 27.1617C9.2184 25.2166 10.3275 22.6389 10.8269 21.5931C10.3801 20.5212 9.34456 17.4833 8.77686 13.9068C8.20915 10.3304 9.1711 7.91991 9.72303 7.16174C10.6166 7.39704 12.7508 8.13429 14.1385 9.20096C15.5262 10.2676 17.6604 12.6258 18.554 13.6715C19.7104 13.3578 22.6856 12.7304 25.3349 12.7304C27.9842 12.7304 30.5389 13.3578 31.4851 13.6715C32.2735 12.7304 34.2132 10.5186 35.664 9.20096C37.1148 7.88331 39.4748 7.24018 40.4737 7.08331C40.7364 7.42318 41.2937 8.41665 41.4199 9.67155C41.5776 11.2402 41.1045 14.5343 40.4737 16.7304C39.9691 18.4872 39.3172 20.5474 39.0544 21.3578C39.1858 21.6454 39.6379 22.5814 40.3948 24.0245C41.1975 25.5548 41.7163 29.0041 41.6629 30.8294Z"
        fill="#FFAA35"
      />
      <path
        d="M28.7238 37.8091C28.5363 38.06 28.3331 38.5409 28.255 38.75V38.6716L28.0206 36.7899C27.578 36.8421 26.4269 36.9467 25.3644 36.9467C24.3019 36.9467 23.0467 36.8421 22.5519 36.7899L22.4738 38.6716C22.4217 38.5932 22.2863 38.3736 22.1613 38.1227C22.005 37.8091 21.4581 37.3387 21.4581 37.1035C21.4581 36.9153 21.1977 36.5025 21.0675 36.3195L20.8331 36.0059H22.2394L22.5519 36.2411C22.8123 36.2149 23.4738 36.194 24.0363 36.3195C24.5988 36.4449 24.9477 35.9536 25.0519 35.6922V34.4378C24.8175 34.281 24.2394 33.9203 23.8019 33.7322C23.255 33.497 22.7081 32.5561 22.8644 31.6153C23.0206 30.6745 23.4894 30.3609 23.8019 30.4393C24.1144 30.5177 26.1456 30.3609 26.4581 30.4393C26.7706 30.5177 27.3175 30.6745 27.5519 31.0665C27.7863 31.4585 27.63 33.0266 27.1613 33.5754C26.7863 34.0144 26.0675 34.3332 25.755 34.4378V35.6922C25.755 35.8491 26.3019 36.2411 26.38 36.3195C26.4581 36.3979 27.63 36.3195 27.8644 36.3195C28.0519 36.3195 28.203 36.1627 28.255 36.0843H29.5831V36.3979C29.5831 36.5547 29.3488 37.2603 29.2706 37.3387C29.1925 37.4171 28.9581 37.4955 28.7238 37.8091Z"
        fill="black"
      />
      <path
        d="M26.2101 37.2916L25.4165 37.9166L24.7816 37.2916L24.226 37.5694L23.7498 37.0833H27.0832L26.607 37.6389L26.2101 37.2916Z"
        fill="black"
      />
      <path
        d="M17.6687 24.4712C16.7389 24.0865 15.7316 23.8301 15.3442 23.75C15.2409 23.8034 15.0343 23.9744 15.0343 24.2308C15.0343 24.5513 14.9568 24.8718 15.0343 25.5128C15.1117 26.1538 15.7316 26.5545 16.8164 27.0353C17.9012 27.516 19.7608 27.9167 21.078 27.9167C22.3953 27.9167 21.1555 26.5545 20.6906 26.234C20.2257 25.9135 18.831 24.9519 17.6687 24.4712Z"
        fill="black"
      />
      <path
        d="M18.9412 22.3447C18.2464 22.4689 17.283 22.5 16.8882 22.5C16.4934 22.4483 15.6564 22.2049 15.4669 21.6457C15.23 20.9467 15.8617 20.1701 16.6513 19.7818C17.441 19.3935 18.4675 19.5488 19.0991 20.4807C19.7308 21.4127 19.8098 22.1893 18.9412 22.3447Z"
        fill="white"
      />
      <path
        d="M33.1643 24.0545C34.0941 23.6699 35.1014 23.4134 35.4888 23.3333C35.5922 23.3867 35.7987 23.5577 35.7987 23.8141C35.7987 24.1346 35.8762 24.4551 35.7987 25.0961C35.7212 25.7372 35.1014 26.1378 34.0166 26.6186C32.9318 27.0993 31.0722 27.5 29.755 27.5C28.4377 27.5 29.6775 26.1378 30.1424 25.8173C30.6073 25.4968 32.002 24.5352 33.1643 24.0545Z"
        fill="black"
      />
      <path
        d="M30.6418 22.3447C31.3366 22.4689 32.3 22.5 32.6948 22.5C33.0896 22.4482 33.9266 22.2049 34.1161 21.6457C34.3529 20.9467 33.7213 20.1701 32.9316 19.7818C32.142 19.3935 31.1155 19.5488 30.4838 20.4807C29.8522 21.4127 29.7732 22.1893 30.6418 22.3447Z"
        fill="white"
      />
      <path
        d="M13.512 16.531C12.9442 17.1511 11.8559 18.5465 11.3827 19.1666C11.0673 18.4948 10.3733 16.7015 10.1209 14.9031C9.86859 13.1046 10.0684 10.3294 10.1998 9.16663C10.5415 9.19247 11.5405 9.4767 12.8023 10.4069C14.064 11.3372 15.9042 13.0684 16.6665 13.8178C15.8516 14.4638 14.0798 15.9108 13.512 16.531Z"
        fill="#FF8C30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99982 10.6433C11.24 11.1608 12.8725 11.9519 13.5566 12.6669C14.2506 13.3923 14.8611 14.3524 15.2236 15C15.7677 14.5488 16.3085 14.1154 16.6665 13.8337C15.8957 13.0818 14.0351 11.3446 12.7592 10.4112C11.4834 9.47776 10.4734 9.19256 10.1278 9.16663C10.0866 9.52882 10.0387 10.0469 9.99982 10.6433Z"
        fill="#FF9A32"
      />
      <path
        d="M36.071 16.531C36.6388 17.1511 37.7271 18.5465 38.2003 19.1666C38.5157 18.4948 39.2097 16.7015 39.4621 14.9031C39.7144 13.1046 39.5146 10.3294 39.3832 9.16663C39.0414 9.19247 38.0425 9.4767 36.7807 10.4069C35.519 11.3372 33.6788 13.0684 32.9165 13.8178C33.7315 14.4638 35.5032 15.9108 36.071 16.531Z"
        fill="#FF8C30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.5832 10.6433C38.3429 11.1608 36.7105 11.9519 36.0263 12.6669C35.3323 13.3923 34.7218 14.3524 34.3595 15C33.8153 14.5488 33.2745 14.1154 32.9165 13.8337C33.6873 13.0818 35.5479 11.3446 36.8237 10.4112C38.0995 9.47776 39.1095 9.19256 39.4551 9.16663C39.4964 9.52882 39.5443 10.0469 39.5832 10.6433Z"
        fill="#FF9A32"
      />
    </svg>
  );
};

export default SHIB;
