import React from "react";

const BTC: React.FC<SVGProps> = (props) => {
  return (
    <svg
      width="51"
      height="50"
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M50.2872 24.921C50.2872 38.6814 39.1305 49.838 25.3642 49.84C11.6078 49.84 0.451132 38.6814 0.453126 24.919C0.449139 11.1566 11.6058 0 25.3682 0C39.1305 0 50.2872 11.1586 50.2872 24.921V24.921Z"
        fill="#EAB300"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.173 18.8163C34.852 15.4789 31.9711 14.3605 28.3307 14.0415V9.41016H25.5136V13.9179C24.7739 13.9179 24.0163 13.9318 23.2647 13.9478V9.41016H20.4477L20.4457 14.0375L20.4155 14.0381L20.4154 14.0381C19.8158 14.0498 19.2258 14.0614 18.6513 14.0614V14.0475L14.7657 14.0455V17.0559C14.7657 17.0559 16.8471 17.016 16.8112 17.0539C17.9536 17.0539 18.3244 17.7158 18.432 18.288V23.5633C18.5118 23.5633 18.6135 23.5673 18.7291 23.5832H18.432L18.4301 30.9738C18.3802 31.3327 18.1689 31.9049 17.3714 31.9068C17.4073 31.9387 15.3239 31.9068 15.3239 31.9068L14.7637 35.2722H18.432C18.8457 35.2722 19.2556 35.2766 19.661 35.2809C19.9239 35.2838 20.1849 35.2866 20.4437 35.2881L20.4457 39.9713H23.2607V35.338C24.0323 35.3539 24.7799 35.3599 25.5116 35.3599L25.5096 39.9713H28.3267V35.2981C33.0637 35.027 36.3831 33.8327 36.7938 29.3828C37.1268 25.8002 35.4421 24.1993 32.7527 23.5533C34.3895 22.724 35.4122 21.2586 35.173 18.8163ZM31.2296 28.8286C31.2296 32.0424 26.1752 31.9696 23.8699 31.9364C23.6653 31.9334 23.4824 31.9308 23.3266 31.9308V25.7244C23.5214 25.7249 23.7584 25.7195 24.0271 25.7135C26.3968 25.6603 31.2296 25.5518 31.2296 28.8286ZM23.7978 22.8926C25.7268 22.9231 29.9177 22.9892 29.9177 20.0744C29.9195 17.0911 25.8811 17.1979 23.9067 17.2502C23.6842 17.2561 23.488 17.2613 23.3266 17.2613V22.8874C23.4611 22.8873 23.6198 22.8898 23.7978 22.8926Z"
        fill="white"
      />
      <path
        d="M18.4361 23.4397H18.8548V23.7487H18.4361V23.4397Z"
        fill="white"
      />
    </svg>
  );
};

export default BTC;
